import { useRefresh } from '~/composables/user/useRefresh'
import { useAccountApi } from '@/api/account'
import useModal from '~/composables/useModal'
import { MODAL_TYPES } from '~/config/constant'
import { useInitData } from '~~/composables/user/useInitData'
import { useAccountStore } from '~/store/account'
export function useCancelPromotion() {
  const { $pinia, $alertMsg } = useNuxtApp()
  const { CANCEL_PROMOTION } = useAccountApi()
  const { $axios } = useNuxtApp()
  const { openModalOne, closeModal } = useModal()
  const isLoadingSubmit = ref(false)
  const { fetchPlan, fetchInitData } = useInitData()
  const { plan, updatePlan } = useAccountStore($pinia)
  const { refreshToken } = useRefresh()
  const submitCancelPromotion = async () => {
    isLoadingSubmit.value = true
    let message = 'Hệ thống xử lý gặp vấn đề, vui lòng thử lại hoặc liên hệ hỗ trợ viên'
    try {
      const response = await $axios.post(CANCEL_PROMOTION)
      if (response && response.data.status === 'OK') {
        $alertMsg('success', 'Hủy khuyến mãi thành công')
        await refreshToken()
        fetchInitData()
        fetchPlan()
        setTimeout(() => {
          hideCancelPromotionPopup()
        }, 200)
      } else {
        if (response?.data?.message) {
          message = response?.data?.message
        }
        $alertMsg('error', message)
      }
      setTimeout(() => {
        isLoadingSubmit.value = false
      }, 500)
    } catch (error: any) {
      setTimeout(() => {
        isLoadingSubmit.value = false
      }, 500)
      let errorMessage = error?.message || message
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage
      }
      $alertMsg('error', errorMessage)
    }
  }
  const openCancelPromotionPopup = () => openModalOne(MODAL_TYPES.CANCEL_PROMOTION)
  const hideCancelPromotionPopup = () => closeModal(MODAL_TYPES.CANCEL_PROMOTION)

  return {
    plan,
    isLoadingSubmit,
    openCancelPromotionPopup,
    submitCancelPromotion,
    hideCancelPromotionPopup
  }
}
